import styled from '@emotion/styled'
import { TFunction } from 'i18next'
import React from 'react'

import { getColor, theme } from '../../Theme'
import { Task, TaskDetailsInput } from '../../types'
import { RestaurantServices } from './RestaurantServices'

interface TextDetailsContainerProps {
  type?: string
}

export const Text = styled.div<TextDetailsContainerProps>`
  ${theme.text('mediumSmall')};
  text-align: ${(p) => (p.type && p.type === 'to' ? 'right' : 'left')};
`

const DetailsContainer = styled.div<TextDetailsContainerProps>`
  ${theme.layout.flexColumn};
  align-items: ${(p) => (p.type === 'from' ? 'flex-start' : 'flex-end')};
  flex-grow: 1;
  flex-basis: 50%;
  color: ${(p) => getColor(p.theme, ['black'], ['grayPink'])};
`

const PaddedBottom = styled.div`
  ${theme.spacing.bottom('small')}
`

const BoldText = styled.div`
  ${theme.text('mediumSmall', 'content', 'bold')};
`

type Props = {
  t: TFunction
  details: TaskDetailsInput
  task: Task
}

const TaskDetails = ({ t, details, task }: Props) => {
  const { isCommuter, type, station, trains, location, lockingInformation } = details
  const train = trains && trains.length === 1 && trains[0]
  return (
    <DetailsContainer type={type}>
      {station && (
        <PaddedBottom>
          <BoldText>{station}</BoldText>
        </PaddedBottom>
      )}
      {isCommuter &&
        trains.length > 1 &&
        trains.map((tr, i) => (
          <Text key={i} type={type}>{`${t('frame')} ${tr.frame || ''} ${t(
            `${type}Train`
          ).toLowerCase()} ${tr.lineId || ''} ${tr.trainNumber}, ${tr.time || ''}`}</Text>
        ))}
      <RestaurantServices task={task} type={type} />
      {train && (
        <Text type={type}>{`${t(`${type}Train`)} ${train.lineId || ''} ${train.trainNumber}${
          train.time ? `, ${train.time}` : ''
        }`}</Text>
      )}
      {location && <Text type={type}>{location}</Text>}
      {lockingInformation && <Text>{`${t('lockingInformation')}: ${lockingInformation}`}</Text>}
    </DetailsContainer>
  )
}

export default TaskDetails
