import { format } from 'date-fns'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useTypedDispatch, useTypedSelector } from '../..'
import { fetchTrainEquipmentInformationAction } from '../../actions/train-equipment-information'
import { selectTrainEquipmentInfoByKey } from '../../reducers/trainEquipmentInfoSlice'
import { Task } from '../../types'
import { EquipmentInfo } from '../../types/Input'
import { Text } from './TaskDetails'

const getInfoKeyFromTask = (task: Task) =>
  `${task.trainNumber}+${task.operatingDate}+${task.fromStation}+${task.toStation}`

const getTimeForTrain = (type: string, equipment: EquipmentInfo) => {
  const train = type === 'to' ? equipment.toTrain : equipment.fromTrain
  const timestamp = type === 'to' ? train.timestampStart : train.timestampEnd
  if (!train || !timestamp) return ''
  return format(new Date(train.timestampStart), 'HH:mm')
}

type RestaurantServicesProps = {
  task: Task
  type: string // to -> toTrain, from -> fromTrain
}

export const RestaurantServices = ({ task, type }: RestaurantServicesProps) => {
  const { t } = useTranslation()
  const dispatch = useTypedDispatch()

  const user = useTypedSelector((state) => state.user)

  const trainEquipmentInfo = useTypedSelector((state) =>
    selectTrainEquipmentInfoByKey(state, getInfoKeyFromTask(task))
  )

  useEffect(() => {
    if (user.personnelGroup === 'A' || user.admin || user.read_admin) {
      if (!trainEquipmentInfo) {
        dispatch(
          fetchTrainEquipmentInformationAction({
            trainNumber: task.trainNumber,
            fromStation: task.fromStation,
            toStation: task.toStation,
            startDateTime: task.taskStartDateTime,
            endDateTime: task.taskEndDateTime,
          })
        )
      }
    }
  }, [
    dispatch,
    task.fromStation,
    task.taskEndDateTime,
    task.taskStartDateTime,
    task.toStation,
    task.trainNumber,
    trainEquipmentInfo,
    user,
  ])

  const filteredInfo =
    trainEquipmentInfo?.equipment.filter(
      (eq) => (type === 'to' && !!eq.toTrain) || (type === 'from' && !!eq.fromTrain)
    ) || []

  const groupedByTrain = filteredInfo.reduce(
    (acc, equipment) => {
      const key = equipment.toTrain
        ? `${equipment.toTrain.trainNumber}+${equipment.toTrain.operatingDay}`
        : `${equipment.fromTrain.trainNumber}+${equipment.fromTrain.operatingDay}`
      if (!acc[key]) {
        acc[key] = []
      }
      acc[key].push(equipment)
      return acc
    },
    {} as Record<string, EquipmentInfo[]>
  )

  return (
    <>
      {Object.entries(groupedByTrain).map(([key, equipments]) => (
        <div key={key}>
          {equipments.map((equipment, index) => (
            <>
              <div key={index}>
                <Text type={type}>
                  {`${t(`restaurantType.${equipment.type.toLowerCase()}`)} ${equipment.wagonCode}`}
                </Text>
              </div>
              {equipments.length === index + 1 && (
                <Text type={type}>
                  {`${t(`${type}Train`)} ${
                    (type === 'to' ? equipments[0].toTrain : equipments[0].fromTrain).trainNumber
                  } `}
                  <strong>{`(${getTimeForTrain(type, equipments[0])})`}</strong>
                </Text>
              )}
            </>
          ))}
        </div>
      ))}
    </>
  )
}
