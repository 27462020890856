import styled from '@emotion/styled'
import WarningIcon from '@mui/icons-material/Warning'
import { Alert } from '@mui/material'
import { TFunction } from 'i18next'
import { useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { logout, subscribePush, unsubscribePush } from '../actions/api'
import Button from '../components/button/Button'
import CalendarButton from '../components/calendar/CalendarButton'
import Link from '../components/Link'
import Page from '../components/page/Page'
import Text from '../components/Text'
import config from '../config'
import { POWER_BI_LINK } from '../constants'
import Info from '../icons/Info'
import Logo from '../icons/Logo'
import Logout from '../icons/Logout'
import Notification from '../icons/Notification'
import Statistics from '../icons/Statistics'
import { standaloneMode } from '../lib/pwa'
import { checkUserForDriverRole, isMaintenanceUser } from '../lib/roleUtils'
import { visitURL } from '../lib/url'
import { defaultTextColor, getColor, theme } from '../Theme'
import { Action, AppState, Dispatch } from '../types'

const Container = styled.div`
  box-sizing: border-box;
  ${theme.layout.fluidWidth(theme.maxWidths.column)};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: ${theme.spacing.sizes.large};
`

const Header = styled.div`
  ${theme.spacing.sides('large')};
  ${theme.spacing.ends('huge')};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: space-between;
  background: ${(p) => getColor(p.theme, ['primaryGreen'], ['nightGray'])};
  color: ${(p) => getColor(p.theme, ['white'], ['grayPink'])};
`

const HeaderContainer = styled.div`
  box-sizing: border-box;
  ${theme.layout.staticWidth(theme.maxWidths.column)};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const HeaderTitle = styled.div`
  margin-top: ${theme.spacing.sizes.large};
  ${theme.text('large', 'content', 'bold')};
  color: ${(p) => getColor(p.theme, ['white'], ['grayPink'])};
`

const HeaderVersion = styled.div`
  ${theme.text('large', 'content')};
  color: ${(p) => getColor(p.theme, ['white'], ['grayPink'])};
`

const Row = styled.a`
  text-decoration: none;
  ${theme.spacing.all('large')};
  background: ${(p) => getColor(p.theme, ['white'], ['nightBlack'])};
  color: ${defaultTextColor};
  box-sizing: border-box;
  border-bottom: 1px solid ${(p) => getColor(p.theme, ['grayBlue'], ['nightSeparator'])};
  align-items: center;
  &:last-child {
    border-bottom: none;
  }
`

const ButtonLink = styled(Link)`
  text-decoration: none;
  ${theme.spacing.all('large')};
  background: ${(p) => getColor(p.theme, ['white'], ['nightBlack'])};
  box-sizing: border-box;
  border-bottom: 1px solid ${(p) => getColor(p.theme, ['grayBlue'], ['nightSeparator'])};
  align-items: center;
  &:last-child {
    border-bottom: none;
  }
`

const IconRow = styled(Row)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
`
const RowIcon = styled.div`
  color: ${(p) => getColor(p.theme, ['primaryBlue'], ['primaryBlue'])};
`

type Props = {
  t: TFunction
  admin: boolean
  isCommuter: boolean
  isMaintenance: boolean
  isCommuterDriver: boolean
  pushStatus: string
  onLogout: () => void
  openAbout: () => void
  openCustomerFeedback: () => void
  openPushMessageAbout: () => void
  openPastSmDisruptions: () => void
}

const sendFeedback = () => window.location.assign('mailto:kentta@evolver.fi')

const OptionsPage = ({
  t,
  admin,
  isCommuter,
  isMaintenance,
  isCommuterDriver,
  pushStatus,
  onLogout,
  openAbout,
  openCustomerFeedback,
  openPushMessageAbout,
  openPastSmDisruptions,
}: Props) => {
  const [unsubscribeAlertText, setUnsubscribeAlertText] = useState('')
  const [unsubscribeAlertLevel, setUnsubscribeAlertLevel] = useState(null)
  const [resubscribeAlertText, setResubscribeAlertText] = useState('')
  const [resubscribeAlertLevel, setResubscribeAlertLevel] = useState(null)

  const isPushEnabled = pushStatus !== 'noPushEnabled'

  const resetAlerts = () => {
    setUnsubscribeAlertLevel(null)
    setUnsubscribeAlertText('')
    setResubscribeAlertLevel(null)
    setResubscribeAlertText('')
  }

  const handleReSubscribe = async () => {
    resetAlerts()
    const unsubscribeResult = await unsubscribePush()
    setUnsubscribeAlertLevel(unsubscribeResult.level)
    setUnsubscribeAlertText(t(`pushMessageAlert.${unsubscribeResult.key}`))
    const subscribeResult = await subscribePush()
    setResubscribeAlertLevel(subscribeResult.level)
    setResubscribeAlertText(t(`pushMessageAlert.${subscribeResult.key}`))
  }

  return (
    <Page menu="options">
      <Header>
        <HeaderContainer>
          <Logo height={30} />
          <HeaderTitle>{t('appName')}</HeaderTitle>
          <HeaderVersion>
            {t('versionNumber', { number: config.version, environment: config.environment })}
          </HeaderVersion>
        </HeaderContainer>
      </Header>
      <Container>
        {unsubscribeAlertText && (
          <Alert
            variant="filled"
            severity={unsubscribeAlertLevel}
            onClose={() => setUnsubscribeAlertText('')}
          >
            {unsubscribeAlertText}
          </Alert>
        )}
        {resubscribeAlertText && (
          <Alert
            variant="filled"
            severity={resubscribeAlertLevel}
            onClose={() => setResubscribeAlertText('')}
          >
            {resubscribeAlertText}
          </Alert>
        )}
        <IconRow onClick={openAbout}>
          <Text>{t('aboutApp')}</Text>
          <RowIcon>
            <Info iconSize="normal" />
          </RowIcon>
        </IconRow>
        {isCommuter && (
          <IconRow href={POWER_BI_LINK} target={'_blank'}>
            <Text>{t('punctualityStatistics')}</Text>
            <RowIcon>
              <Statistics iconSize="small" />
            </RowIcon>
          </IconRow>
        )}
        <IconRow onClick={onLogout}>
          <Text>{t('logout')}</Text>
          <RowIcon>
            <Logout iconSize="normal" />
          </RowIcon>
        </IconRow>
        {isCommuter && (
          <IconRow onClick={openCustomerFeedback}>
            <Text>{t('customerFeedback')}</Text>
            <RowIcon>
              <Notification iconSize="normal" />
            </RowIcon>
          </IconRow>
        )}
        {isCommuterDriver && config.environment !== 'prod' && (
          <IconRow onClick={openPastSmDisruptions}>
            <Text>{t('smDisruption.pageDescription')}</Text>
            <RowIcon>
              <WarningIcon />
            </RowIcon>
          </IconRow>
        )}
        {/* TODO: Clearer visuals for feedback mailto button*/}
        <IconRow onClick={sendFeedback}>
          <Text color="primaryBlue">{t('sendFeedbackEmail')}</Text>
        </IconRow>
        {!isMaintenance && (
          <>
            <CalendarButton optionsVersion={true} />
            {isPushEnabled && (
              <IconRow onClick={openPushMessageAbout}>
                <Text>{t('pushMessageAbout')}</Text>
              </IconRow>
            )}
          </>
        )}
        {admin && <ButtonLink to="/dev">{t('developerOptions')}</ButtonLink>}
        {isPushEnabled && <Button onClick={handleReSubscribe}>{t('pushMessageRenew')}</Button>}
      </Container>
    </Page>
  )
}

OptionsPage.displayName = 'OptionsPage'

const mapStateToProps = (state: AppState) => ({
  admin: state.user.admin || state.user.read_admin,
  isCommuter:
    state.user.admin ||
    state.user.read_admin ||
    state.user.commuter_driver ||
    state.user.commuter_manager ||
    state.user.commuter_conductor ||
    !!state.user.actAsCommuter,
  isMaintenance: isMaintenanceUser(state.user),
  isCommuterDriver: checkUserForDriverRole(state.user) === 'commuter_driver',
  pushStatus: state.system.pushStatus.key,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onLogout: () => {
    dispatch(logout as unknown as Action)
    if (standaloneMode()) {
      window.open(`${config.apiBaseUrl}/logout`)
    } else {
      window.location.href = `${config.apiBaseUrl}/logout`
    }
  },
  openAbout: () => dispatch(visitURL('/about') as unknown as Action),
  openCustomerFeedback: () => dispatch(visitURL('/customerFeedback') as unknown as Action),
  openPushMessageAbout: () => dispatch(visitURL('/pushMessages') as unknown as Action),
  openPastSmDisruptions: () => dispatch(visitURL('/smDisruptions') as unknown as Action),
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(OptionsPage))
