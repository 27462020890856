import { createSlice } from '@reduxjs/toolkit'

import { RootState } from '..'
import { fetchTrainEquipmentInformationAction } from '../actions/train-equipment-information'
import { ActionStatus, TrainEquipmentInfo } from '../types/Input'

export interface TrainEquipmentInfoState {
  status: ActionStatus
  trainEquipmentInfoByTrain: Record<string, TrainEquipmentInfo>
  error?: string
}

const initialState: TrainEquipmentInfoState = {
  status: 'none',
  trainEquipmentInfoByTrain: {},
}

export const trainEquipmentInfoSlice = createSlice({
  name: 'train-equipment-info',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTrainEquipmentInformationAction.pending, (state) => {
      state.status = 'loading'
      state.error = undefined
    })

    builder.addCase(fetchTrainEquipmentInformationAction.fulfilled, (state, { payload }) => {
      let newTrainInfos = {}
      const key = `${payload.trainNumber}+${payload.operatingDate}+${payload.fromStation}+${payload.toStation}`
      newTrainInfos = { ...newTrainInfos, ...{ [key]: payload } }
      state.trainEquipmentInfoByTrain = { ...state.trainEquipmentInfoByTrain, ...newTrainInfos }
      state.status = 'succeeded'
    })

    builder.addCase(fetchTrainEquipmentInformationAction.rejected, (state, { payload }) => {
      if (payload) {
        state.error = payload.message
      }
      state.status = 'failed'
    })
  },
})

export default trainEquipmentInfoSlice.reducer

export const selectTrainEquipmentInfoByKey = (state: RootState, key: string) =>
  state.trainEquipmentInfo.trainEquipmentInfoByTrain[key]
